import React from 'react';
import Insurance from '../../components/travelTips/InsuranceComponent';
import Footer from '../../components/FooterComponent';

import MainPageMetadata from '../../metadata/MainPageMetadata';

function InsurancePage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <Insurance />
            <Footer />
        </div>
    )
}

export default InsurancePage;
