import React from 'react';

import TravelTipsHeader from './TravelTipsHeaderComponent';

function Insurance(props) {
    return (
        <React.Fragment>
            <TravelTipsHeader image="https://live.staticflickr.com/65535/50379867998_6778717354_o.jpg"
                thumb="https://live.staticflickr.com/65535/50379867998_4a6ca945d5_t.jpg"
                title="Insurance" subtitle="Travel Tips" />

            <div className="container">
                <div className="city-text">
                    <div className="col-12 col-lg-10 ms-auto me-auto" >
                        <p style={{ marginTop: '40px', marginBottom: '40px' }}>
                        The need for travel insurance is a big question we always ask ourselves when traveling to another country.
                        It is a big expense, and the truth is most often than not we are not gonna use it, so there is always the idea maybe this time we could leave with out.
                        <br/><br/>
                        Our experience tells us it depends a lot on your destination. There are some factors to take into account, such as the duration of the
                        trip, the destination area, the activities planned, and the luggage that we will bring. For example for a trip inside your home
                        country, or if you are from UE and are travelling to another UE country, maybe it doesn't justify to acquire this insurance.
                        <br/><br/>
                        There are numerous insurers that offer plans that are appropriate for every situation, but prices may not match
                        all portfolios. We almost always opt by <b>World Nomads</b>, which offers one of the most complete insurances on the
                        market, or by <b>IATI</b>, which offers more economical solutions and allows multi-travel insurance (a huge saving for those who, like us, make several trips a year).
                        </p>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>World Nomads</h1>
                            <p>
                            On long-term trips, to destinations that involve a high risk or where health expenses are very high, we
                            usually opt by the <b>World Nomads Explorer Plan</b>. In our opinion, it is the insurance that offers the best
                            coverage on the market and whether we are going to spend 3 months in the United States (where health care is
                            very expensive) or “backpacking” in Latin America (where the risk of robberies is a little high) we have
                            no hesitation.
                            <br></br>
                            The only negative aspects of the World Nomads Explorer Plan are the price and the need to pay a deductible fee
                            whenever you need to activate the insurance.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>IATI</h1>
                            <p>
                            IATI insurance prices are considerably cheaper. As they offer many plans (with very different coverage
                            values) they are well suited to any type of trip. For example, the IATI Basic is perfect for a short trip
                            through Europe or when you are visiting a country in Asia. The price is unbeatable and there is no
                            need to pay a deductible fee to activate the insurance!
                            Another great advantage of IATI is that it has started to allow multi-travel insurances to a lot of countries.
                            For those who, like us, travel a lot during the year, you can achieve great savings.
                            If you choose IATI, you can use our link and you will have a <b>5% discount</b> on the purchase of your travel insurance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Insurance;
